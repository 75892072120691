import { useCallback, useEffect, useState } from 'react'
import { useTheme } from 'next-themes'
import { useAuth } from '@/providers/Auth'
import { Theme } from '@/types/global'
import { useProfileThemeApi } from '../api/administration/profile/theme'

type ThemeType = 'light' | 'dark'

type Props = {
  theme: ThemeType
  setTheme: (theme: Theme) => void
  loading: boolean
}

const usePersistedTheme = (): Props => {
  const [loading, setLoading] = useState(false)
  const { theme, setTheme: setThemeInternal } = useTheme()
  const { useUpdateProfileTheme } = useProfileThemeApi()
  const { userContext, isLogged, refreshProfile } = useAuth()
  const { mutateAsync: mutateAsyncUpdateProfileTheme } = useUpdateProfileTheme()

  useEffect(() => {
    if (userContext?.userAccountInformation?.theme) {
      setThemeInternal(userContext.userAccountInformation.theme.toLowerCase())
    }
  }, [setThemeInternal, userContext?.userAccountInformation?.theme])

  const setTheme = useCallback(
    async (theme: Theme) => {
      if (!isLogged) {
        setThemeInternal(theme.toLowerCase())
        return
      }

      setLoading(true)
      setThemeInternal(theme.toLowerCase())

      try {
        const upperCaseTheme = (theme.charAt(0).toUpperCase() +
          theme.slice(1).toLowerCase()) as Theme
        await mutateAsyncUpdateProfileTheme({ theme: upperCaseTheme })
        await refreshProfile()
      } catch (error) {
        console.error('Failed to save theme:', error)
        setThemeInternal(userContext.userAccountInformation.theme.toLowerCase())
      } finally {
        setLoading(false)
      }
    },
    [
      isLogged,
      mutateAsyncUpdateProfileTheme,
      refreshProfile,
      setThemeInternal,
      userContext?.userAccountInformation?.theme
    ]
  )

  return { theme: theme as ThemeType, setTheme, loading }
}

export default usePersistedTheme
