import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { CgSpinner } from 'react-icons/cg'
import { cn } from '@/lib/utils'

export const buttonVariants = cva(
  'relative inline-flex items-center justify-center whitespace-nowrap text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:opacity-50 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'bg-primary hover:bg-primary-foreground text-accent-foreground dark:text-accent-100 shadow disabled:hover:bg-primary',
        outline:
          'border border-primary bg-transparent shadow-sm text-primary hover:bg-primary-foreground hover:text-accent-900 disabled:hover:bg-transparent disabled:hover:text-primary',
        secondary:
          'bg-secondary text-accent-100 dark:text-accent-900 shadow-sm hover:bg-secondary-foreground disabled:hover:bg-secondary',
        outlineSecondary:
          'border border-secondary bg-transparent shadow-sm text-secondary hover:bg-secondary-foreground hover:text-accent disabled:hover:bg-transparent disabled:hover:text-secondary',
        destructive:
          'bg-destructive text-white shadow-sm hover:bg-destructive/90 disabled:hover:bg-destructive',
        outlineDestructive:
          'border border-destructive bg-transparent shadow-sm text-destructive hover:bg-destructive hover:text-white disabled:hover:bg-transparent disabled:hover:text-destructive',
        neutral:
          'border border-gray-400 bg-background text-gray-700 shadow-sm hover:border-gray-500 hover:bg-gray-900 hover:text-gray-100 disabled:hover:bg-background disabled:hover:text-gray-700',
        ghost:
          'text-gray-700 bg-gray-200 hover:text-gray-800 hover:bg-gray-300 shadow-none disabled:opacity-40 disabled:dark:opacity-40 disabled:hover:bg-gray-200 disabled:hover:text-gray-800',
        link: 'text-secondary hover:text-secondary-700 bg-transparent underline-offset-4 hover:underline shadow-none disabled:hover:text-secondary disabled:hover:no-underline',
        unstyled: ''
      },
      size: {
        sm: 'h-8 rounded-md text-sm py-1.5 lg:py-2 px-3 lg:px-4',
        default:
          'h-10 rounded-md text-sm lg:text-md py-1.5 lg:py-2 px-2.5 lg:px-4',
        lg: 'h-12 rounded-md text-md lg:text-lg py-2 lg:py-2.5 px-4 lg:px-5',
        icon: 'h-9 w-9 rounded-md'
      },
      loading: {
        true: 'cursor-wait'
      },
      fullWidth: {
        true: 'w-full'
      }
    },
    compoundVariants: [
      {
        variant: 'unstyled',
        size: undefined,
        className: ''
      }
    ],
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    showSkeleton?: boolean
    timeoutSkeleton?: number
    childClassName?: string
  }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      loading,
      asChild = false,
      fullWidth,
      showSkeleton = false,
      timeoutSkeleton,
      childClassName,
      // dataTestId,
      ...props
    },
    ref
  ) => {
    const [isLoadingSkeleton, setIsLoadingSkeleton] =
      React.useState<boolean>(true)

    React.useEffect(() => {
      if (!timeoutSkeleton) {
        setIsLoadingSkeleton(false)
        return
      }
      const timer = setTimeout(() => {
        setIsLoadingSkeleton(false)
      }, timeoutSkeleton)
      return () => clearTimeout(timer)
    }, [timeoutSkeleton])

    if (showSkeleton || isLoadingSkeleton) {
      return (
        <div
          className={cn(
            buttonVariants({ size }),
            'skeleton w-28',
            fullWidth && 'w-full'
          )}
          role="status"
        />
      )
    }

    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          variant === 'unstyled'
            ? ''
            : buttonVariants({ variant, size, loading, fullWidth }),
          className,
          'flex gap-2'
        )}
        ref={ref}
        // data-testid={dataTestId}
        {...props}
      >
        <div
          style={{ visibility: loading ? 'hidden' : 'visible' }}
          className={cn('flex items-center gap-2', childClassName)}
        >
          {children}
        </div>
        {loading && (
          <CgSpinner
            className="absolute inset-0 m-auto text-xl animate-spin"
            data-testid="loading-spinner"
          />
        )}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export default Button
