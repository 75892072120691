import * as React from 'react'
import Button, { ButtonProps } from '@/ui/atoms/Button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/ui/shadcn/tooltip'

type ButtonWithTooltipProps = ButtonProps & {
  tooltipText: string
  tooltipSideOffset?: number
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left'
}

const ButtonWithTooltip = React.forwardRef<
  HTMLButtonElement,
  ButtonWithTooltipProps
>(
  (
    {
      tooltipText,
      tooltipSideOffset = 4,
      tooltipSide,
      children,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <TooltipProvider delayDuration={50}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button ref={ref} {...buttonProps}>
              {children}
            </Button>
          </TooltipTrigger>
          <TooltipContent sideOffset={tooltipSideOffset} side={tooltipSide}>
            {tooltipText}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }
)

ButtonWithTooltip.displayName = 'ButtonWithTooltip'

export default ButtonWithTooltip
