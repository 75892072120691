import { useMutation, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { UpdateProfileThemeRequest } from './useProfileThemeApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useProfileThemeApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()

  const useUpdateProfileTheme = () =>
    useMutation({
      mutationFn: (data: UpdateProfileThemeRequest) =>
        handleApi<UpdateProfileThemeRequest, void>(
          `/administration/profile/theme`,
          'PATCH',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ProfileTheme]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.UserContext]
        })
      }
    })

  return {
    useUpdateProfileTheme
  }
}

export default useProfileThemeApi
