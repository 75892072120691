import * as React from 'react'
import placeholder from '@tiptap/extension-placeholder'
import { countries } from 'countries-list'
import { hasFlag } from 'country-flag-icons'
import * as Flags from 'country-flag-icons/react/3x2'
import Gleap from 'gleap'
import { useLocale } from '@/providers/Locale'
import { cn } from '@/lib/utils'
import * as SelectShadcn from '@/ui/shadcn/select'

const options = Object.entries(countries)
  .map(([code, country]) => {
    if (code !== 'US' && code !== 'BR') return undefined
    if (!hasFlag(code)) return undefined
    const FlagComponent = (Flags as any)[code]

    return {
      value: country.name,
      label: (
        <div className="flex items-center justify-center">
          <FlagComponent style={{ display: 'inline-block', width: '25px' }} />
        </div>
      )
    }
  })
  .filter(Boolean) as OptionType[]

type OptionType = {
  label: React.ReactNode
  value: string
}

const renderOptions = (options: OptionType[], isDisabled: boolean) =>
  options.map((option, id) => (
    <SelectShadcn.SelectItem
      key={`${option.value}-${id}`}
      value={option.value}
      className={cn(
        'py-0.5 pl-0 pr-0 cursor-pointer w-fit',
        !isDisabled && 'focus:bg-gray-50 focus:text-secondary-700',
        isDisabled && 'text-gray-500 bg-gray-200 cursor-not-allowed'
      )}
      classNameIndicator="hidden"
    >
      {option.label}
    </SelectShadcn.SelectItem>
  ))

const SelectLang: React.FC = () => {
  const { setLang, lang, loading } = useLocale()

  const langFull = React.useMemo(() => {
    return lang === 'PtBr' ? 'Brazil' : 'United States'
  }, [lang])

  return (
    <SelectShadcn.Select
      onValueChange={(value) => {
        setLang(value === 'Brazil' ? 'PtBr' : 'EnUs')
        Gleap.setLanguage(value === 'Brazil' ? 'pt-BR' : 'en-US')
      }}
      value={langFull}
      disabled={loading}
    >
      <SelectShadcn.SelectTrigger
        id="select-lang"
        className={cn(
          'w-fit min-w-7 flex item-center gap-2 h-fit py-0 px-0 border-transparent rounded-none focus:border-transparent focus:ring-0 ring-0 data-[state=open]:border-transparent data-[state=open]:ring-0 shadow-none',
          loading ? 'text-gray-500 cursor-not-allowed' : ''
        )}
        showIndicator={false}
        aria-disabled={loading}
      >
        <SelectShadcn.SelectValue placeholder={placeholder} />
      </SelectShadcn.SelectTrigger>
      <SelectShadcn.SelectContent
        className="w-fit min-w-fit -left-1"
        classNameViewport="w-fit min-w-fit "
      >
        {renderOptions(options, loading)}
      </SelectShadcn.SelectContent>
    </SelectShadcn.Select>
  )
}

SelectLang.displayName = 'SelectLang'

export default SelectLang
