import React from 'react'
import { tv } from 'tailwind-variants'
import { cn } from '@/lib/utils'

const typographyVariants = {
  'title-6xl-semibold':
    'font-display text-4xl sm:text-5xl md:text-6xl font-semibold tracking-tighter',
  'title-6xl-regular':
    'font-display text-4xl sm:text-5xl md:text-6xl font-regular tracking-tighter',
  'title-5xl-semibold':
    'font-display text-3xl sm:text-4xl md:text-5xl font-semibold tracking-tighter',
  'title-5xl-medium':
    'font-display text-3xl sm:text-4xl md:text-5xl font-medium tracking-tighter',
  'title-5xl-regular':
    'font-display text-3xl sm:text-4xl md:text-5xl font-regular tracking-tighter',
  'title-4xl-medium':
    'font-display text-2xl sm:text-3xl md:text-4xl font-medium tracking-normal ',
  'title-4xl-regular':
    'font-display text-2xl sm:text-3xl md:text-4xl font-regular tracking-normal ',
  'title-3xl-bold':
    'font-display text-xl sm:text-2xl md:text-3xl font-bold tracking-normal ',
  'title-3xl-semibold':
    'font-display text-xl sm:text-2xl md:text-3xl font-semibold tracking-normal ',
  'title-3xl-medium':
    'font-display text-xl sm:text-2xl md:text-3xl font-medium tracking-normal ',
  'title-3xl-regular':
    'font-display text-xl sm:text-2xl md:text-3xl font-regular tracking-normal ',
  'title-2xl-medium':
    'font-display text-lg sm:text-xl md:text-2xl font-medium tracking-normal ',
  'title-2xl-regular':
    'font-display text-lg sm:text-xl md:text-2xl font-regular tracking-normal ',
  'title-xl-medium':
    'font-display text-md sm:text-lg md:text-xl font-medium tracking-normal ',
  'title-xl-regular':
    'font-display text-md sm:text-lg md:text-xl font-regular tracking-normal ',
  'title-lg-medium':
    'font-display text-sm sm:text-md md:text-lg font-medium tracking-normal ',
  'title-lg-regular':
    'font-display text-sm sm:text-md md:text-lg font-regular tracking-normal ',
  'text-base-medium': 'font-body text-base font-medium tracking-tighter',
  'text-base-regular': 'font-body text-base font-regular tracking-tighter',
  'text-base-semibold': 'font-body text-base font-semibold tracking-tighter',
  'text-sm-semibold': 'font-body text-sm font-semibold tracking-tighter',
  'text-sm-medium': 'font-body text-sm font-medium tracking-tighter',
  'text-sm-regular': 'font-body text-sm font-regular tracking-tighter',
  'text-xs-medium': 'font-body text-xs font-medium tracking-tighter',
  'text-xs-regular': 'font-body text-xs font-regular tracking-tighter'
}

const typographySkeletonVariants = {
  'title-6xl-semibold': 'h-8 w-96',
  'title-6xl-regular': 'h-8 w-96',
  'title-5xl-semibold': 'h-8 w-80',
  'title-5xl-medium': 'h-8 w-80',
  'title-5xl-regular': 'h-8 w-80',
  'title-4xl-medium': 'h-8 w-80',
  'title-4xl-regular': 'h-8 w-80',
  'title-3xl-bold': 'h-8 w-80',
  'title-3xl-semibold': 'h-8 w-80',
  'title-3xl-medium': 'h-8 w-80',
  'title-3xl-regular': 'h-8 w-80',
  'title-2xl-medium': 'h-8 w-80',
  'title-2xl-regular': 'h-8 w-80',
  'title-xl-medium': 'h-6 w-60',
  'title-xl-regular': 'h-6 w-60',
  'title-lg-medium': 'h-6 w-60',
  'title-lg-regular': 'h-6 w-60',
  'text-base-medium': 'h-5 w-48',
  'text-base-regular': 'h-5 w-48',
  'text-base-semibold': 'h-5 w-48',
  'text-sm-semibold': 'h-4 w-44',
  'text-sm-medium': 'h-4 w-44',
  'text-sm-regular': 'h-4 w-44',
  'text-xs-medium': 'h-4 w-40',
  'text-xs-regular': 'h-4 w-40'
}

type TypographyVariants = keyof typeof typographyVariants

export interface Props extends React.HTMLAttributes<HTMLElement> {
  type?: 'p' | 'span' | 'label' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  variant?: TypographyVariants
  htmlFor?: string
  classNameSkeleton?: string
  showSkeleton?: boolean
}

const Typography = React.forwardRef<any, Props>(
  (
    {
      type = 'p',
      variant = 'text-base-regular',
      className,
      children,
      showSkeleton = false,
      classNameSkeleton,
      ...rest
    },
    ref
  ) => {
    const TagName = type

    const typography = tv({
      base: 'text-accent-500 dark:text-gray-600 text-start',
      variants: {
        variant: typographyVariants
      }
    })

    const skeletonStyle = `skeleton ${typographySkeletonVariants[variant]}`

    if (showSkeleton) {
      return <div className={cn(skeletonStyle, classNameSkeleton)} />
    }

    return (
      <TagName
        ref={ref}
        className={typography({
          variant,
          className
        })}
        {...rest}
      >
        {!showSkeleton ? children : null}
      </TagName>
    )
  }
)

Typography.displayName = 'Typography'

export default Typography
